import { ReactElement } from 'react';
import { DefaultImageObject, PhotoCategory } from 'types/Types';
import { ReactComponent as Gallery } from 'assets/Gallery.svg';
import styles from './AlbumCover.module.scss';
import { getDateForPhoto } from 'helpers/helpers';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import StrapiResponsiveImage from 'components/common/StrapiResponsiveImage/StrapiResponsiveImage';

type AlbumCoverProps = {
  album: PhotoCategory;
  defaultPhoto: DefaultImageObject | null;
};

const getCover = (
  album: PhotoCategory,
  defaultPhoto: DefaultImageObject | null
) => {
  if (album?.attributes?.cover?.data) {
    return album?.attributes?.cover?.data;
  }
  if (album?.attributes?.photo?.data) {
    return album?.attributes?.photo?.data[0];
  }
  return defaultPhoto?.attributes?.image?.data;
};

function AlbumCover({ album, defaultPhoto }: AlbumCoverProps): ReactElement {
  const { t } = useTranslation();

  const cover = getCover(album, defaultPhoto);

  return (
    <Link
      to={album.id ? `/photogallery/${album?.id}` : {}}
      className={styles.photoWrapper}
    >
      <div className={styles.photo}>
        <div className={styles.imgWrapper}>
          {cover && <StrapiResponsiveImage image={cover} className={styles.photo} />}
        </div>
        <div className={styles.photoIcon}>
          <div className={styles.photoIconWrapper}>
            <Gallery />
            <span className={styles.photoCount}>
              {album?.attributes?.photo?.data?.length ?? 0}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.descriptionWrapper}>
        <div className={styles.descriptionContent}>
          <span className={styles.descriptionDate}>
            {Boolean(album.attributes.date) &&
              getDateForPhoto(album.attributes.date)}
          </span>
          <span className={styles.description}>
            {
              album.attributes.description
                .replace(' (фото:', ' (Фото:')
                .replace(' (ФОТО:', ' (Фото:')
                .split(' (Фото:')[0]
            }
          </span>
          <span className={styles.authors}>
            {t('homepage.photoGalleryWidget.photo')}{' '}
            {album.attributes.description
              .replace(' (фото:', ' (Фото:')
              .replace(' (ФОТО:', ' (Фото:')
              .split(' (Фото:')[1]
              ?.slice(0, -1)}
          </span>
        </div>
      </div>
    </Link>
  );
}

export default AlbumCover;
