import Image from 'model/entity/loko/type/Image';
import IResponse from './base/IResponse';
import Article from 'model/entity/loko/type/Article';
import ArenaCategory from 'model/entity/loko/type/ArenaCategory';
import ArenaSector from 'model/entity/loko/type/ArenaSector';
import SubscriptionType from 'model/entity/loko/type/SubscriptionType';
import Subscription from 'model/entity/loko/type/Subscription';

export type MenuElementDto = {
  id: number;
  attributes: {
    createdAt: Date;
    updatedAt: Date;
    name: string;
    display_name: string;
    display_order: number;
    main_menu_item: {
      data: Array<SubMenuElementDto>;
    };
  };
};

export type AdvertisingWidgetDto = {
  id: number;
  attributes: {
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    text?: string;
    padding?: number;
    link: string;
    isImageInCenter?: boolean;
    game?: {
      data: GameWithLogo;
    };
    preview?: { data: Image };
    main_image?: { data: Image };
  };
};

export type SubMenuElementDto = {
  id: number;
  attributes: {
    display_name: string;
    name: string;
    url: string;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    display_order: number;
    display_name_admin: string;
    is_external: boolean;
  };
};

export type PartnerDto = {
  id: number;
  attributes: {
    name: string;
    url: string;
    priority: number;
    enabled: boolean;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    logo: {
      data: {
        id: number;
        attributes: {
          name: string;
          alternativeText: string;
          caption: string;
          width: number;
          height: number;
          formats: object;
          hash: string;
          ext: string;
          mime: string;
          size: number;
          url: string;
          previewUrl: object;
          provider: string;
          provider_metadata: object;
          createdAt: Date;
          updatedAt: Date;
        };
      };
    };
    partner_category: {
      data: {
        id: number;
        attributes: {
          name: string;
          priority: number;
          enabled: boolean;
          createdAt: Date;
          updatedAt: Date;
        };
      };
    };
    partner_type: {
      data: {
        id: number;
        attributes: {
          type: string;
          createdAt: Date;
          updatedAt: Date;
        };
      };
    };
  };
};

export type PartnerHeaderDto = {
  id: number;
  attributes: {
    text: string;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
  };
};

export type SocialLinksHeader = {
  id: number;
  attributes: {
    title: string;
    description: string;
    createdAt: Date;
    updatedAt: Date;
  };
};

export type StaticPageDto = {
  id: number;
  attributes: {
    Title: string;
    subtitle: string;
    Annotation: string;
    Text: string;
    add_ons: [
      {
        id: number;
        name: AddOns;
      },
    ];
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    hide_ad: boolean;
    slider?: MediaSliderDto;
    show_share_in_socials: boolean;
  };
};

export type AwayMatchesPageDto = {
  id: number;
  attributes: {
    title: string;
    text: string;
    signup_button_text: string;
    hide_ad: boolean;
    slider?: MediaSliderDto;
  };
};

export type MediaSliderDto = {
  id: number;
  media: {
    data: Image[];
  };
  youtube_urls: {
    url: string;
  }[];
};

export type MediaSlideProps = {
  url: string;
  title: string;
  isVideo: boolean;
  isYoutube: boolean;
};

export type MediaSliderProps = {
  media: MediaSlideProps[];
};

export type SocialLinkDto = {
  id: number;
  attributes: {
    url: string;
    createdAt: Date;
    updatedAt: Date;
    image: { data: Image };
  };
};

export type DefaultImageObject = {
  id: number;
  attributes: {
    createdAt: Date;
    description: string;
    image: { data: Image };
    updatedAt: Date;
  };
};

export type File = {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: {
    thumbnail: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path: string;
      size: number;
      width: number;
      height: number;
    };
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  provider_metadata: object;
  createdAt: string;
  updatedAt: string;
};

export type BookletAppealDto = {
  attributes: {
    createdAt: string;
    locale: string;
    text: string;
    updatedAt: string;
  };
  id: number;
};

export type Match = {
  data: {
    id: number;
    attributes: {
      date: Date;
      team_1: string;
      team_2: string;
      score_1: number;
      score_2: number;
      goals_details: string;
      team_roster_1: string;
      team_roster_2: string;
      author: string;
      period_scores: string;
      stadium: string;
      tickets_link: string;
      shots_blocked: string;
      shots_on_goal: string;
      throw_ins: string;
      penalty_minutes: string;
      createdAt: Date;
      updatedAt: Date;
      publishedAt: Date;
      label: string;
    };
  };
};

export type TopPartnersDto = {
  id: number;
  attributes: {
    name: string;
    url: string;
    createdAt: Date;
    updatedAt: Date;
    image: { data: Image };
    order_index: number;
  };
};

type AsideNavMenuElement = {
  name: string;
  url: string;
  isExternal: boolean;
  isActive: boolean;
};

export type AsideNavMenuType = {
  header: string;
  elements: Array<AsideNavMenuElement>;
  currentElement?: AsideNavMenuElement;
};

export type TeamWithLogo = {
  data: {
    id: number;
    attributes: {
      code: string;
      code_2: string;
      id_league_web: number;
      city: string;
      name: string;
      main_color: string;
      additional_color: string;
      createdAt: Date;
      updatedAt: Date;
      logo: {
        data: {
          id: number;
          attributes: {
            name: string;
            alternativeText: string;
            caption: object;
            width: number;
            height: number;
            formats: object;
            hash: string;
            ext: string;
            mime: string;
            size: number;
            url: string;
            previewUrl: string;
            provider: string;
            provider_metadata: object;
            createdAt: Date;
            updatedAt: Date;
          };
        };
      };
    };
  };
};

export type PlayersCitizenshipWithLogo = {
  data: {
    id: number;
    attributes: {
      name: string;
      flag: {
        data: {
          id: number;
          attributes: {
            name: string;
            alternativeText: string;
            url: string;
          };
        };
      };
    };
  };
};

export type GameWithLogo = {
  id: number;
  attributes: {
    date: Date;
    score_1: number;
    score_2: number;
    goals_details: string;
    team_roster_1: string;
    team_roster_2: string;
    author: string;
    period_scores: string;
    stadium: string;
    tickets_link: string;
    shots_blocked: string;
    shots_on_goal: string;
    throw_ins: string;
    penalty_minutes: string;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    label: string;
    team_1: TeamWithLogo;
    team_2: TeamWithLogo;
    continuation: Continuation;
    article: {
      data: Article;
    };
    season: {
      data: Season;
    };
    is_current: boolean;
    display_value: string;
    broadcast_url?: string;
    highlights: Array<Highlight>;
    game_category?: GameCategory;
  };
};

export type Highlight = {
  url: string;
  name: string;
  id: number | string;
  type_id: number;
  type_name: string;
};

export type Broadcast = {
  url: string;
  name?: string;
  id?: string;
};

export type Continuation = {
  data: {
    id: number;
    attributes: {
      index: number;
      name: string;
      abbreviation: string;
      createdAt: Date;
      updatedAt: Date;
    };
  };
};

export type ScheduleDay = {
  day: Date;
  game?: GameWithLogo;
};

export type ScheduleGame = {
  id: number;
  attributes: {
    date: Date;
    score_1: number;
    score_2: number;
    goals_details: string;
    team_roster_1: string;
    team_roster_2: string;
    author: string;
    period_scores: string;
    stadium: string;
    tickets_link: string;
    shots_blocked: string;
    shots_on_goal: string;
    throw_ins: string;
    penalty_minutes: string;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    label: string;
    team_1: TeamWithLogo;
    team_2: TeamWithLogo;
    continuation: Continuation;
    season: {
      data: Season;
    };
    article: {
      data: {
        id: number;
        attributes: object;
      };
    };
  };
};

export type Season = {
  id: number;
  attributes: {
    code: string;
    name: string;
    years_interval: string;
    team: string;
    display: boolean;
    active: boolean;
    club_id: number;
    league_id: number;
    team_id: number;
    junior: boolean;
    year: number;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    is_cross_playoff: boolean;
    cross_playoff_start_round?: number;
  };
};

export type ClubSeason = {
  data: {
    id: number;
    attributes: {
      name: string;
      display: boolean;
      active: boolean;
      junior: boolean;
      createdAt: Date;
      updatedAt: Date;
      years_interval: string;
    };
  };
};

export type TeamDto = {
  id: number;
  attributes: {
    code: string;
    code_2: string;
    id_league_web: number;
    city: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
  };
};

export type Team = {
  data: TeamDto;
};

export type Position = {
  data: {
    id: number;
    attributes: {
      position: string;
      createdAt: Date;
      updatedAt: Date;
    };
  };
};

export type PlayerDto = {
  id: number;
  attributes: {
    name: string;
    surname: string;
    createdAt: Date;
    updatedAt: Date;
    birth: Date;
    details: string;
    details_link: string;
    active: boolean;
    senior: boolean;
    country: string;
    id_league_web: number;
    contract_ends: Date;
    birth_place: string;
    height: number;
    weight: number;
    position: Position;
    photo: { data: Image };
    rosters: { data: Array<Roster> };
  };
};

export type Player = {
  data: PlayerDto;
};

export type ExecutiveDto = {
  id: number;
  attributes: {
    name: string;
    surname: string;
    patronymic?: string;
    birth?: Date;
    birth_place: string | null;
    position: string;
    biography?: string;
    coaching_experience: number | null;
    lokomotive_start_date: string | null;
    executive_career?: string;
    player_career?: string;
    photo?: { data: Image };
    bg_photo?: { data: Image };
    statistics?: Array<StatisticsDto>;
    type: ExecutiveType;
    citizenship: PlayersCitizenshipWithLogo;
    is_catastrophe: boolean;
    display_order: number;
    executive_rosters?: { data: Array<ExecutiveRosterDto> };
    team_filter: { data: TeamDto[] };
  };
};

export type ExecutiveRosterDto = {
  id: number;
  attributes: {
    position: string;
    executive: {
      data: ExecutiveDto;
    };
    season: {
      data: Season;
    };
  };
};

export type StatisticsDto = {
  id: number;
  league?: string;
  season?: string;
  team?: string;
  regular_season?: PlayerStatisticsDto;
  playoffs?: PlayerStatisticsDto;
  is_goalkeeper: boolean;
};

export type PlayerStatisticsDto = {
  id: number;
  games?: number;
  goals?: number;
  passes?: number;
  points?: number;
  penalties?: number;
  missed_goals?: number;
  shutouts?: number;
  goals_against_average?: number;
  save_percentage?: number;
};

export type Assignment = {
  data: {
    id: number;
    attributes: {
      name: string;
      createdAt: Date;
      updatedAt: Date;
    };
  };
};

export type RosterDto = {
  id: number;
  attributes: {
    beginning: boolean;
    active: boolean;
    number: number;
    height: number;
    weight: number;
    trauma: boolean;
    farm_club: boolean;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    season: ClubSeason;
    team: Team;
    assignment: Assignment;
    player: Player;
    display_order: number;
  };
};

export type RosterSeason = {
  id: number;
  attributes: {
    name: string;
    display: boolean;
    active: boolean;
    junior: boolean;
    createdAt: Date;
    updatedAt: Date;
    years_interval: string;
    team: Team;
  };
};

export type Grip = {
  data: {
    id: number;
    attributes: {
      grip: string;
      createdAt: Date;
      updatedAt: Date;
    };
  };
};

export type Roster = {
  id: number;
  attributes: {
    beginning: boolean;
    active: boolean;
    number: number;
    height: number;
    weight: number;
    trauma: boolean;
    farm_club: boolean;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    season: ClubSeason;
  };
};

export type PlayerWithRelations = {
  id: number;
  attributes: {
    name: string;
    surname: string;
    createdAt: Date;
    updatedAt: Date;
    birth: Date;
    details: string;
    annotation: string;
    details_link: string;
    active: boolean;
    senior: boolean;
    country: string;
    id_league_web: number;
    contract_ends: Date;
    birth_place: string;
    height: number;
    weight: number;
    position: Position;
    grip: Grip;
    rosters: {
      data: Array<Roster>;
    };
    photo: { data: Image };
    bg_photo: { data: Image };
    main_bg_photo: { data: Image };
    citizenship: PlayersCitizenshipWithLogo;
    hockey_school: string;
    hide_contract_info: boolean;
    debut: Date;
    statistic?: Array<StatisticsDto>;
    contract_type: {
      data: {
        id: number;
        attributes: {
          type: string;
        };
      };
    };
  };
};

export type PlayerSeasonStatsDtoReadable = {
  id?: number;
  position?: string;
  clubidt?: number;
  clubidg?: number;
  teamname?: string;
  teamnameen?: string;
  fullname?: string;
  firstname?: string;
  lastname?: string;
  nameen?: string;
  birthdate?: Date;
  stick?: string;
  height?: number;
  weight?: number;
  games_played?: number;
  number?: number;
  captain?: string;
  goals?: number;
  assists?: number;
  points?: number;
  plus_minus?: number;
  plus?: number;
  minus?: number;
  penalty_in_minutes?: number;
  power_play_goals?: number;
  shorthanded_goals?: number;
  game_winning_goals?: number;
  shootouts_deciding_shots?: number;
  shots_on_goal?: number;
  shots_on_goal_percent?: number;
  faceoffs?: number;
  faceoffs_wons?: number;
  faceoffs_wons_percent?: number;
  hits?: number;
  time_on_ice_average?: number;
  shifts_on_game_average?: number;
  shots_on_game_average?: number;
  goals_against?: number;
  goals_against_average?: number;
  time_on_ice?: number;
  savings?: number;
  savings_percent?: number;
  shootouts?: number;
  notkhl?: string;
  experience?: string;
  clubs?: string;
  // helper fields got from DB and not from KHL stats
  idlocal?: number;
  namelocal?: string;
  tka?: number;
  otg?: number;
  eqgoals?: number;
  overtime_goals?: number;
  shots_on_goal_average?: number;
  time_in_equals_average?: number;
  shootouts_in_equals_average?: number;
  time_in_more_average?: number;
  shots_in_more_average?: number;
  time_in_less_average?: number;
  shots_in_less_average?: number;
  blocks?: number;
  foals_against?: number;
  interception_transmission?: number;
  wins?: number;
  losses?: number;
  game_with_b_series?: number;
  photo?: { data: Image };
};

export type PlayerSeasonStatsDto = {
  '@_id'?: string;
  '@_pos'?: string; // position
  '@_clubidt'?: number; // through season updated KHL team id e.g. Loko at 2022
  '@_clubidg'?: number; // constant KHL club id
  '@_teamname'?: string;
  '@_teamnameen'?: string;
  '@_clubids'?: number;
  '@_name'?: string; // full name
  '@_firstname'?: string; // first name, often contains patromimic abbreviation
  '@_lastname'?: string;
  '@_nameen'?: string;
  '@_birthdate'?: Date;
  '@_stick'?: string; // grip
  '@_height'?: number;
  '@_weight'?: number;
  '@_gp'?: number; // games played
  '@_jn'?: number; // jersey number
  '@_capt'?: string; // captain
  '@_g'?: number; // goals
  '@_a'?: number; // assists
  '@_pts'?: number; // points
  '@_pm'?: number; // plus/minus
  '@_plus'?: number;
  '@_minus'?: number;
  '@_pim'?: number; // penalty in minutes (штрафное время)
  '@_sham'?: number; // ?
  '@_ppg'?: number; // power play goals
  '@_shg'?: number; // shorthanded goals
  '@_gwg'?: number; // game winning goals
  '@_eqg'?: number; // шайбы в равенстве?
  '@_otg'?: number; // шайбы в овертайме
  '@_sds'?: number; // shootouts deciding wins
  '@_sog'?: number; // shots on goal
  '@_sog_pct'?: number; // shots on goal percentage
  '@_fo'?: number; // face offs
  '@_fow'?: number; // face offs wins
  '@_fo_pct'?: number; // face offs wins
  '@_foa'?: number; // фолы против?
  '@_hits'?: number; // силовые приемы
  '@_bls'?: number; // блокированные броски
  '@_toi_avg'?: number; // Avg time on ice/Game
  '@_sft_avg'?: number; // Avg. shifts/Game
  '@_sog_avg'?: number; // Avg. shots/Game
  '@_ga'?: number; // Goals against
  '@_gaa'?: number; // Goal against average
  '@_toi'?: number; // time on ice
  '@_sv'?: number; // Saves
  '@_sv_pct'?: number; // Saves percentage
  '@_so'?: number; // Shutouts
  '@_toa_avg'?: string;
  '@_notkhl'?: string;
  '@_experience'?: string;
  '@_se'?: number;
  '@_clubs'?: string;
  '@_w'?: number; // выигрыши
  '@_l'?: number; // проигрыши
  '@_sop'?: number; // игры с буллитными сериями
  '@_tie_avg'?: number; // Среднее время на площадке при игре в равных составах за игру
  '@_sfte_avg'?: number; // Среднее количество смен при игре в равных составах за игру
  '@_tipp_avg'?: number; // Среднее время на площадке при игре в большинстве за игру
  '@_sftpp_avg'?: number; // Среднее количество смен при игре в большинстве за игру
  '@_tish_avg'?: number; // Среднее время на площадке при игре в меньшинстве за игру
  '@_sftsh_avg'?: number; // Среднее количество смен при игре в меньшинстве за игру
  '@_tka'?: number; //отб
};

export type PlayerSeasonStats = {
  id?: number;
  position?: string;
  number?: number;
  gamesPlayed?: number;
  goals?: number;
  assists?: number;
  points?: number;
  plusMinus?: number;
  plus?: number;
  minus?: number;
  penaltyInMinutes?: number;
  gameWinningGoals?: number;
  faceOffs?: number;
  faceOffsWin?: number;
  faceOffPercent?: number;
  goalsAllowed?: number;
  goalAgainstAverage?: number;
  timeOnIce?: number;
  saves?: number;
  savesPercent?: number;
  shutouts?: number;
  equalityGoals?: number;
  overtimeGoals?: number;
  powerPlayGoals?: number;
  shorthandedGoals?: number;
  shootoutsDecidingShots?: number;
  shotsOnGoal?: number;
  shotsOnGoalPercent?: number;
  blockedThrows?: number;
  hits?: number;
  timeOnIceAverage?: number;
  shiftsOnGameAverage?: number;
  shotsOnGameAverage?: number;
  shotsOnGame?: number;
  goalsAgainst?: number;
  foulsAgainst?: number;
  shootouts?: number;
  notkhl?: string;
  experience?: string;
  clubs?: string;
  powerTechniques?: number;
  wins?: number;
  lose?: number;
  shootoutSeries?: number;
  timeInEqualAverage?: number;
  shiftsEqual?: number;
  timeInMostAverage?: number;
  shiftsMost?: number;
  timeInSmallerAverage?: number;
  shiftsSmaller?: number;
  tka?: number;
  teamname?: string;
};

export type RosterDtoWithStats = {
  player: RosterDto;
  stats?: PlayerSeasonStats;
};

export type PhotoFormat = {
  ext: string;
  url: string;
  size: number;
  width: number;
  height: number;
};

export type PhotoFormats = {
  large: PhotoFormat;
  medium: PhotoFormat;
  small: PhotoFormat;
  thumbnail: PhotoFormat;
};

export type Photo = {
  id: number;
  attributes: {
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: PhotoFormats;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: object;
    createdAt: Date;
    updatedAt: Date;
    keywords: string;
    copyright: string;
  };
};

export type PhotoCategory = {
  id: number;
  attributes: {
    description: string;
    display: boolean;
    display_match: boolean;
    season: string;
    sort_id: number;
    date: Date;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    match: Match;
    team: Team;
    cover: {
      data: Image;
    };
    photo: {
      data: Array<Image>;
    };
  };
};

export type ForumCategory = {
  id: number;
  attributes: {
    name: string;
    createdAt: Date;
    updatedAt: Date;
  };
};

export type UserMin = {
  id: number;
  username: string;
  nickname: string;
  karma: number;
  avatar_url: string;
  status: number;
  name: string;
  surname: string;
};

export type User = {
  id: number;
  username: string;
  nickname: string;
  email: string;
  name: string;
  surname: string;
  city: string;
  password?: string;
  karma: number;
  confirmed: boolean;
  avatar?: {
    url?: string;
  };
  status: number;
};

export type ForumPost = {
  id: number;
  category: ForumCategory;
  user: UserMin;
  attributes: {
    date_time: Date;
    name: string;
    text: string;
    host: string;
    ip: string;
    display: boolean;
    timestamp: Date;
    karma: number;
    createdAt: Date;
    updatedAt: Date;
    locale: string;
  };
};

export type ForumPostToSend = {
  id: number | null;
  data: {
    category: number;
    user: number;
    date_time: string;
    name: string;
    text: string;
    display: boolean;
    timestamp: string;
    host?: string;
    ip?: string;
    karma?: number;
  };
};

export type ForumConditions = {
  data: {
    id: number;
    attributes: {
      text: string;
      createdAt: Date;
      updatedAt: Date;
      locale: string;
    };
  };
};

export type CurrentUser = {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  surname: string;
  nickname: string;
  // TODO: adjust the below
  city: string;
  date_registerd: Date;
  date_time: Date;
  avatar: { data: Image } | null;
  id_buddy: number;
  id_clud: number | null;
  //role: TODO,
  karma: number;
  agreement_accepted: boolean;
};

export type AlbumSimple = {
  description: string;
  photo: Array<string>;
};

export type CommonVideo = {
  id: string;
  title: string;
  previewUrl: string;
  date: string;
  url: string;
};

export type DivisionsData = {
  id: string;
  name: string;
};

export type ConferenceTree = {
  id: string;
  name: string;
  divisions: Array<DivisionsData>;
};

export type StandingsReadableDto = {
  club_id: string;
  conference: string;
  conference_name: string;
  conference_rank: number;
  division: string;
  division_name: string;
  division_rank: number;
  goals_against: string;
  goals_for: string;
  games_played: string;
  id: string;
  losses: string;
  logo: string;
  name: string;
  overtime_losses: string;
  overtime_wins: string;
  penalty_in_minutes: string;
  penalty_in_minutes_against: string;
  points: string;
  points_percent: string;
  rank: string;
  shootouts: string;
  shootouts_against: string;
  shootouts_losses: string;
  shootouts_wins: string;
  wins: string;
  city: string;
};

export type StandingsDto = {
  '@_clubid': string;
  '@_conference': string;
  '@_conference_name': string;
  '@_conference_rank': number;
  '@_division': string;
  '@_division_name': string;
  '@_division_rank': number;
  '@_ga': string;
  '@_gf': string;
  '@_gp': string;
  '@_id': string;
  '@_l': string;
  '@_logo': string;
  '@_name': string;
  '@_otl': string;
  '@_otw': string;
  '@_pim': string;
  '@_pima': string;
  '@_pts': string;
  '@_pts_pct': string;
  '@_rank': string;
  '@_so': string;
  '@_soa': string;
  '@_sol': string;
  '@_sow': string;
  '@_w': string;
  '@_city': string;
};

export type PlayoffStandingsReadableDto = {
  id: string;
  round: string;
  round_name: string;
  teama: string;
  teamb: string;
  score: string;
  teamaCode: string;
  teambCode: string;
  teamaName: string;
  teambName: string;
  teamaCity: string;
  teambCity: string;
  date: Date;
  time: string;
  arena: string;
};

export type PlayoffStandingsDto = {
  '@_id': string;
  '@_round': string;
  '@_roundname': string;
  '@_teama': string;
  '@_teamb': string;
  '@_score': string;
  '@_homeName_l3_en': string;
  '@_visitorName_l3_en': string;
  '@_homeName': string;
  '@_visitorName': string;
  '@_homeCity': string;
  '@_visitorCity': string;
  '@_date': Date;
  '@_time': string;
  '@_arena': string;
};

export type TopPlayerCardType = {
  player: PlayerWithRelations | null;
  stats: PlayerSeasonStatsDtoReadable | null;
  category: string;
  value: number;
};

export type Product = {
  id: number;
  name: string;
  code: string;
  weight: number;
  insert_date: Date;
  active: boolean;
  novelty: boolean;
  discount: boolean;
  week_top_sale: boolean;
  availability: number;
  detail_info: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  price: number;
  default_price: number;
  uuid_1c: string;
  group_by_id_1c: string;
  common_name: string;
  category?: {
    id: number;
    name: string;
    url: string;
    display_order: number;
    display_name?: string;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    uuid_1c: string;
  };
  subcategory?: {
    id: number;
    name: string;
    url: string;
    display_order: number;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    display_name: string;
  };
  pictures?: {
    data: Array<any>;
  };
  image?: {
    images?: Array<ProductImage>;
    main_image?: ProductImage;
  };
};

export type ProductImageFormat = {
  url: string;
};

export type ProductImage = {
  formats: {
    small: ProductImageFormat;
    medium: ProductImageFormat;
    thumbnail: ProductImageFormat;
  };
  url: string;
};

export type SmileDto = {
  id: number;
  attributes: {
    text: string;
    createdAt: Date;
    updatedAt: Date;
    locale: string;
    image: { data: Image };
  };
};

export type DocumentsDto = {
  id: number;
  attributes: {
    name: string;
    document: { data: Image };
    document_link: string;
    display_order: number;
    hide_document: boolean;
    sys_name: string;
  };
};

export type YoutubeRequestOptions = {
  maxResults?: number;
  pageToken?: string;
  channelID?: string;
  publishedAfter?: string;
  publishedBefore?: string;
  sortOrder?: string;
};

export type Banner = {
  id: number;
  attributes: {
    url: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    image: { data: Image };
    imageSquare: { data: Image };
  };
};

export type BannerData = {
  data: Array<Banner>;
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};

export type Background = {
  id: number;
  attributes: {
    url: string;
    gradient_height?: number;
    image_height?: number;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    image?: { data: Image };
  };
};

export type BackgroundsData = {
  data: Array<Background>;
};

export type GameCategory = {
  data: {
    id: number;
    attributes: {
      createdAt: string;
      updatedAt: string;
      locale: string;
      name: string;
    };
  };
};

export type SubscriptionTypeData = IResponse<SubscriptionType>;

export enum SideMenuWidgets {
  News = 'Новости',
  LokoTV = 'Локо-ТВ',
  Socials = 'Социальные сети',
  Leaders = 'Лидеры',
}

export enum AddOns {
  achievements = 'Достижения',
}

export enum StandingsTypes {
  number = 'number',
  string = 'string',
  time = 'time',
}

export type SideMenuPartnerDto = {
  id: number;
  attributes: {
    priority: number;
    partner: {
      data: {
        attributes: {
          name: string;
          url: string;
          enabled: boolean;
          logo: {
            data: {
              attributes: {
                url: string;
              };
            };
          };
          partner_category: {
            data: {
              id: number;
              attributes: {
                name: string;
                priority: number;
                enabled: boolean;
              };
            };
          };
        };
      };
    };
  };
};

export type SideMenuPartners = {
  id: number;
  partner: {
    name: string;
    url: string;
    enabled: boolean;
    logo: {
      url: string;
    };
    partner_category: {
      data: {
        id: number;
        attributes: {
          name: string;
          priority: number;
          enabled: boolean;
          createdAt: Date;
          updatedAt: Date;
        };
      };
    };
  };
  priority: number;
};

export type SideMenuWidget = {
  id: number;
  attributes: {
    priority: number;
    widget: SideMenuWidgets;
  };
};

export type SubscriptionData = IResponse<Subscription>;

export type ArenaCategoryData = IResponse<ArenaCategory>;

export type ArenaSectorData = IResponse<ArenaSector>;

export type SponsorType = {
  id: number;
  attributes: {
    name: string;
    description: string;
    logo: { data: Image };
  };
};

export type ProtocolGoalInfoDto = {
  '@_num': string;
  '@_score': string;
  '@_per': string;
  '@_time': string;
  '@_pmg': string;
  '@_teamId': string;
  '@_scorer': string;
  '@_scorer_id': string;
  '@_scorer_g': string;
  '@_assist_1': string;
  '@_assist_1_id': string;
  '@_assist_1_a': string;
  '@_assist_2': string;
  '@_assist_2_id': string;
  '@_assist_2_a': string;
  '@_oniceA': string;
  '@_oniceB': string;
};

export type PenaltyDto = {
  '@_pnum': string;
  '@_time': string;
  '@_code': string;
  '@_per': string;
  '@_tbeg': string;
  '@_tend': string;
  '@_appointed': string;
  '@_whoServed': string;
  '@_notServed': string;
  '@_converted': string;
};

export type ProtocolPenaltyInfoDto = {
  Penalty: Array<PenaltyDto> | PenaltyDto;
  '@_team': string;
  '@_teamId': string;
};

export type ProtocolPlayerStatsListDto = {
  '@_teamId': string;
  PlayerStats: PlayerSeasonStatsDto[];
};

export type ProtocolPenaltyInfo = {
  Penalty: {
    playerNumber: string;
    time: string;
    code: string;
    period: string;
    tbeg: string;
    tend: string;
    appointed: string;
    whoServed: string;
    notServed: string;
    converted: string;
  };
  team: string;
  teamId: string;
};

export type ProtocolPlayerStatsList = {
  teamId: string;
  payerStats: string;
};

export type ProtocolGameSummaryDto = {
  '@_team': string;
  '@_p1': string;
  '@_p2': string;
  '@_p3': string;
  '@_ot': string;
  '@_summary'?: string;
};

export type ProtocolShotDto = {
  '@_pnoA': string;
  '@_pnoB': string;
  '@_gkA': string;
  '@_gkB': string;
  '@_sc': string;
};

export type GameProtocolDto = {
  GameSummary: {
    Goals: {
      Goal: ProtocolGoalInfoDto[];
    };
    Penalties: ProtocolPenaltyInfoDto[];
    PlayerStatsList: ProtocolPlayerStatsListDto[];
    ShotsList:
      | {
          Shot: ProtocolGameSummaryDto[];
        }
      | undefined;
    HitsList:
      | {
          Hit: ProtocolGameSummaryDto[];
        }
      | undefined;
    BlsList:
      | {
          Bls: ProtocolGameSummaryDto[];
        }
      | undefined;
    Takeaways:
      | {
          Takeaway: ProtocolGameSummaryDto[];
        }
      | undefined;
    Pass_interceptions:
      | {
          Pass_interception: ProtocolGameSummaryDto[];
        }
      | undefined;
    Giveaways:
      | {
          Giveaway: ProtocolGameSummaryDto[];
        }
      | undefined;
    TimeOnAttack:
      | {
          Toa: ProtocolGameSummaryDto[];
        }
      | undefined;
    Shootout:
      | {
          Shot: ProtocolShotDto[];
        }
      | undefined;
    '@_tournamentId': string;
    '@_id': string;
    '@_date': string;
    '@_time': string;
    '@_number': string;
    '@_audience': string;
    '@_refm1': string;
    '@_id_refm1': string;
    '@_refm2': string;
    '@_id_refm2': string;
    '@_refl1': string;
    '@_id_refl1': string;
    '@_refl2': string;
    '@_id_refl2': string;
    '@_trainA': string;
    '@_trainB': string;
    '@_teama': string;
    '@_teamb': string;
    '@_homeId': string;
    '@_visitorId': string;
    '@_homeName': string;
    '@_homeCity': string;
    '@_visitorName': string;
    '@_visitorCity': string;
    '@_score': string;
    '@_ots': string;
    '@_lastUpdate': string;
  };
};

export type ProtocolTeamDatawithPlayerStats = {
  khlId: string;
  inGameId: string;
  name: string;
  city: string;
  logo: string;
  score: string;
  playerStats: PlayerSeasonStatsDtoReadable[] | undefined;
};

export type Achievement = {
  id: number;
  attributes: {
    title: string;
    description: string;
    period: string;
    page_id: number;
  };
};

export enum ExecutiveType {
  EXECUTIVE = 'executive',
  COACH = 'coach',
  MANAGER = 'manager',
  STAFF = 'staff',
  PRESS = 'press',
}

export enum PlayerInfoType {
  PHOTOS = 'photos',
  NEWS = 'news',
  VIDEOS = 'videos',
}

export type ArenaPointsType = {
  right: number;
  bottom: number;
  icon: string;
  alt: string;
};

export type ArenaConstructionAlbumsResponse = {
  attributes: {
    createdAt: string;
    publishedAt: string;
    photo_alboms: {
      data: Array<PhotoCategory>;
    };
  };
};

export type ComplexInformationResponse = {
  createdAt: string;
  publishedAt: string;
  title: string;
  area: number;
  capacity: number;
  vip_capacity: number;
  description: string;
  objects: {
    data: Array<ArenaAboutObject>;
  };
  images: { data: Image[] };
};

export type ArenaAboutObject = {
  id: number;
  attributes: ArenaAboutObjectAttributes;
};

export type ArenaAboutObjectAttributes = {
  title: string;
  description: string;
  images: { data: Image[] };
  sys_name: string;
  createdAt: string;
  updatedAt: string;
};

export type ArenaTodayResponse = {
  createdAt: string;
  publishedAt: string;
  title: string;
  area: number;
  capacity: number;
  vip_capacity: number;
  description: string;
  images: { data: Image[] };
};

export type SubscriptionPrivilegesPageDto = {
  data: {
    id: number;
    attributes: {
      title: string;
      subscription_title: string;
      subscription_description: string;
      subscription_image: { data: Image };
      slider: MediaSliderDto;
    };
  };
};

export type InlineBanner = {
  id: number;
  attributes: {
    link: string;
    image: { data: Image };
  };
};

export type CurrentSeason = {
  men: string;
  lko: string;
  l76: string;
  catastrophe: string;
};

export type LokomotivTeams = {
  men: TeamWithLogo;
  lko: TeamWithLogo;
  l76: TeamWithLogo;
};

export enum SeasonsTeamCode {
  men = 'men',
  lko = 'lko',
  l76 = 'l76',
  catastrophe = 'catastrophe',
}

export type SortElement = {
  key: string;
  desc: boolean;
  type: StandingsTypes;
};

export type PenaltyKind = {
  id: number;
  attributes: {
    code: number;
    ru: string;
    en: string;
  };
};

export type TicketPrice = {
  id: number;
  category_a_plus: number;
  category_a: number;
  category_b: number;
  category_c: number;
};

export type GetTranscriptionsResponse = {
  transcriptions: Transcription[];
};

export type Transcription = {
  id: number;
  summary: string;
  time: string;
};
