import { ReactElement } from 'react';
import styles from './LiveTextBroadcastCard.module.scss';

type LiveTextBroadcastCardProps = {
  time: string;
  text: string;
};

function LiveTextBroadcastCard({
  time,
  text,
}: LiveTextBroadcastCardProps): ReactElement {
  return (
    <div className={styles.liveTextBroadcastCard}>
      <div className={styles.liveTextBroadcastCardTime}>{time}</div>
      <div className={styles.liveTextBroadcastCardText}>
        {text.split('\n').map((p) => (
          <p className={styles.liveTextBroadcastCardTextParagraph}>{p}</p>
        ))}
      </div>
    </div>
  );
}

export default LiveTextBroadcastCard;
