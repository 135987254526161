import { ReactElement } from 'react';
import classNames from 'classnames';
import TopStoryItem from './TopStoryItem/TopStoryItem';
import { ReactComponent as Arrow } from '../../assets/upper_widget_header.svg';
import styles from './TopStory.module.scss';
import Article from 'model/entity/loko/type/Article';
import { useTranslation } from 'react-i18next';
import SliderWrapper from 'components/SliderWrapper/SliderWrapper';

type TopStoryProps = {
  articles: Article[];
  startIndex?: number;
  isColumnStyle?: boolean;
  count?: number;
  displayInRow?: number;
  isAdaptivVersion?: boolean;
  isHomePageMobileVersion?: boolean;
  isGridStyle?: boolean;
  useImage?: boolean;
};

function TopStory({
  articles,
  startIndex = 1,
  isColumnStyle,
  count = 3,
  displayInRow = 3,
  isAdaptivVersion = false,
  isHomePageMobileVersion = false,
  isGridStyle = false,
  useImage = false,
}: TopStoryProps): ReactElement {
  const { t } = useTranslation();
  return (
    <div className={styles.topStory}>
      {!isHomePageMobileVersion ? (
        <div
          id={'top-story-widget'}
          className={classNames(styles.topStoryWidget, {
            [styles.topStoryWidgetColumn]: isColumnStyle,
            [styles.topStoryWidgetGrid]: isGridStyle,
            [styles.topStoryWidgetRow]: !isColumnStyle,
          })}
        >
          {articles &&
            articles.map(
              (article, i) =>
                i >= startIndex &&
                i < startIndex + count && (
                  <TopStoryItem
                    key={article.id}
                    article={article}
                    isColumnStyle={isColumnStyle}
                    useImage={useImage}
                  />
                )
            )}
        </div>
      ) : (
        <SliderWrapper
          slides={articles.map(
            (article, i) =>
              i >= startIndex &&
              i < startIndex + count && (
                <div style={{ height: '350px' }}>
                  <TopStoryItem
                    key={article.id}
                    article={article}
                    isColumnStyle={false}
                    useImage={useImage}
                  />
                </div>
              )
          )}
          dots={false}
          speed={500}
          adaptiveHeight
          arrows={false}
          marginRightHidden
          infinite
          lazyLoad='anticipated'
        />
      )}
      <a className={styles.link} href={'/news'}>
        <div className={styles.linkContainer}>
          <span className={styles.linkText}>
            {t('homepage.topStoryMoreNews')}
          </span>
          <Arrow className={styles.arrowIcon} />
        </div>
      </a>
    </div>
  );
}

export default TopStory;
