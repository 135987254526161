import Image from 'model/entity/loko/type/Image';
import ImageFormat from 'model/entity/loko/type/ImageFormat';
import { FC, ImgHTMLAttributes, useEffect, useRef, useState } from 'react';

export type StrapiResponsiveImageProps = {
  image?: Image | null;
} & ImgHTMLAttributes<HTMLImageElement>;

const StrapiResponsiveImage: FC<StrapiResponsiveImageProps> = (props) => {
  const { image } = props;
  const [src, setSrc] = useState<string>('');
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    // Берём все форматы что имеются в порядке возрастания
    if (!image) {
      setSrc('');
      return;
    }

    const formats = [
      image.attributes.formats?.thumbnail,
      image.attributes.formats?.small,
      image.attributes.formats?.medium,
      image.attributes.formats?.large,
      image.attributes,
    ].filter(
      (format) => format !== null && format !== undefined
    ) as ImageFormat[];

    // Обработка события изменения размера картинки
    const updateSrc = (width: number, height: number) => {
      const selectedSource =
        formats.find(
          (format) => width <= format.width && height <= format.height
        ) ?? formats[formats.length - 1];
      if (selectedSource) {
        setSrc(selectedSource.url);
      }
    };

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        updateSrc(entry.contentRect.width, entry.contentRect.height);
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [
    image,
    image?.attributes.url,
    image?.attributes.formats,
    image?.attributes,
  ]);

  return (
    <img
      src={src}
      alt={image?.attributes.alternativeText ?? ''}
      ref={ref}
      {...props}
    />
  );
};

export default StrapiResponsiveImage;
