import { ReactElement } from 'react';
import styles from './ImageBgPageWrapper.module.scss';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../../store/store';
import { observer } from 'mobx-react-lite';
import BackgroundInfo from '../../../store/BackgroundStore/BackgroundInfo';
import Colors from 'constants/colors';
import ImageLoader from '../ImageLoaders/ImageLoader/ImageLoader';
import StrapiResponsiveImage from '../StrapiResponsiveImage/StrapiResponsiveImage';

interface ImageBgPageWrapperProps {
  imageInfo?: BackgroundInfo;
  children: React.ReactNode;
}

const ImageBgPageWrapper = observer(
  ({ children, imageInfo }: ImageBgPageWrapperProps): ReactElement => {
    const route = useLocation().pathname;
    const { backgroundStore } = useStore();

    const info: BackgroundInfo =
      imageInfo ?? backgroundStore.getBackgroundByUrl(route);

    return (
      <div className={styles.wrapper}>
        <div>{children}</div>
        <div className={styles.background}>
          {info.img_url || info.image ? (
            <div className={styles.grid}>
              {info.image ? (
                <StrapiResponsiveImage
                  image={info.image}
                  className={styles.img}
                  style={{ height: `${info.image_height}px` }}
                />
              ) : (
                <ImageLoader
                  image={info.image ?? info.img_url ?? ''}
                  className={styles.img}
                  styles={{ height: `${info.image_height}px` }}
                />
              )}

              <div
                className={styles.gradient}
                style={{
                  background: `linear-gradient(0deg, ${Colors.PRIMARY_BLUE} 0%, ${Colors.TRANSPARENT} ${info.gradient_height}%)`,
                }}
              ></div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

export default ImageBgPageWrapper;
